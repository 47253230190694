<template>
  <div>
    <a-modal v-model:visible="visible" title="新增员工" @ok="handleOk" okText="修改" cancelText="取消" :afterClose="closeModal"
      width="30vw">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="姓名">
          <a-input v-model:value="data.name" />
        </a-form-item>
        <a-form-item label="手机">
          <a-input v-model:value="data.name" />
        </a-form-item>
        <a-form-item label="邮箱">
          <a-input v-model:value="data.name" />
        </a-form-item>
        <a-form-item label="性别">
          <a-input v-model:value="data.name" />
        </a-form-item>
        <a-form-item label="岗位">
          <a-input v-model:value="data.name" />
        </a-form-item>
        <a-form-item label="状态">
          <a-input v-model:value="data.name" />
        </a-form-item>

      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { reactive } from "vue";
import { Update } from "/src/api/menu.js";
export default {
  components: {
  },
  data() {
    return {
      visible: this.modelValue,
      medicine: "",
      data: {
        name: undefined,
        description: undefined,
      },
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {
  },
  activated() {
  },
  //获取父元素传值
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: {}
    },
  },
  computed: {

  },
  methods: {

    async handleOk() {
      let _this = this;

      let res = await Update({ id: this.record.parentId, name: this.data.name })
      if (res.errCode == 0) {
        _this.$message.success("修改成功", function () { });
        _this.visible = false
        this.data = {
          name: undefined,
          description: undefined,
        }
      }
    },
    closeModal() {
      this.$emit('update:modelValue', false);
    }
    //初始化data所有数据，并关闭modal
  },
  watch: {
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.head {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
}

.head span {
  font-weight: bold;
  margin-right: 10px;
}
</style>
